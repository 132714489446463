import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { auth } from '../configs/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN } from '../constant/routes';
import { signOut } from 'firebase/auth';
import { getStorageData, setStorageData } from '../services/storage';
import { UserContextType } from '../models/context-types.model';
import { User } from '../models/user.model';

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);


  useEffect(() => {
    const data = getStorageData('user');
    if (data && !user) {
      setUser(data);
    }

  }, [user, location.pathname, navigate]);

  const persistUser = (user: User | null) => {
    setStorageData('user', user);
    setUser(user);
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
    signOut(auth);
    navigate(LOGIN);
  };

  return (
    <UserContext.Provider value={{ user, setUser: persistUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
