import { Link, useNavigate } from 'react-router-dom';
import RecipeOptions from '../recipeOption';
import { FC } from 'react';
import { Recipe } from '../../../models/recipe.model';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/20/solid';
import { useUser } from '../../../context/userContext';

type RecipeCardProps = {
  recipe: Recipe;
  refreshList?: () => void;
  index: number;
};

export const RecipeCard: FC<RecipeCardProps> = ({ recipe, refreshList, index }) => {
  const { user } = useUser();
  const isCloned = user?.uid !== recipe?.scannedById;


  return (
    <div
      style={{ animationDelay: `${index * 100}ms` }}
      className={`relative animate__animated animate__fadeIn flex flex-col text-gray-700 bg-white shadow-md bg-clip-border rounded-xl`}
    >
      <div className="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-white shadow-lg bg-clip-border rounded-xl h-80">
        <Link to={`/recipe/${recipe.id}`}>
          <img
            src={recipe.image || 'https://via.placeholder.com/150'}
            alt={recipe.recipe_name || 'Recipe Image'}
            className="w-full h-full object-cover cursor-pointer"
          />
        </Link>

        <div className="absolute top-2 right-2 z-10">
          <RecipeOptions recipe={recipe} />
        </div>
        {isCloned && (
          <div className="absolute bottom-2 left-2 z-10 w-8 h-8 rounded-l bg-slate-800/10 p-1">
            <ClipboardDocumentCheckIcon className="text-white" />
          </div>
        )}
      </div>

      <div className="p-4">
        <h2 className="text-gray-800 font-bold text-xl mb-2 cursor-pointer">
          {recipe.recipe_name || 'Recipe Name'}
        </h2>
        <p className="text-gray-700"></p>
        <p className="text-gray-700 mt-2 clamp-3 capitalize">
          {recipe.ingredients
            ? recipe.ingredients.map((item) => item.name).join(', ')
            : 'No ingredients available'}
          .<span className="whitespace-pre">{'\n'}</span>
          <span className="whitespace-pre">{'\n'}</span>
          <span className="text-clip overflow-hidden ...">
            {recipe.instructions && recipe.instructions.length > 0
              ? recipe.instructions[0] + '..'
              : 'No instructions available'}
          </span>
        </p>
        <p className="text-gray-700 mt-2">
          <strong>Servings:</strong> {recipe?.servings || 'N/A'}
        </p>
        <p className="text-gray-700 mt-2">
          <strong>Total Time:</strong> {recipe?.total_time || 'N/A'}
        </p>
      </div>
    </div>
  );
};

export const RecipeSearchCard: FC<RecipeCardProps> = ({ recipe, index }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{ animationDelay: `${index * 100}ms` }}
      className={`relative animate__animated animate__fadeIn cursor-pointer flex flex-col text-gray-700 bg-white shadow-lg bg-clip-border rounded-xl`}
    >
        <div className="p-4" onClick={() =>  navigate(`/recipe/${recipe.id}`)}>
          <div className="flex items-center text-xs mb-2">
            <span className="text-gray-500">
              {" "}
              ⌚️ Calories: {recipe.calories}
            </span>
            <span
              className="invisible relative rounded-full bg-gray-50 mx-4 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
            >
              {recipe.vegan ? "🥗 Vegan" : "🥩 Non-vegan"}
            </span>
          </div>
          <h2 className="text-gray-800 font-bold text-xl mb-2 cursor-pointer">
            {recipe.recipe_name || "Recipe Name"}
          </h2>
          <p className="text-gray-700"></p>
          <p className="text-gray-700 mt-2 clamp-3 capitalize">
            {recipe.ingredients
              ? recipe.ingredients.map((item) => item.name).join(", ")
              : "No ingredients available"}
            .<span className="whitespace-pre">{"\n"}</span>
            <span className="whitespace-pre">{"\n"}</span>
            <span className="text-clip overflow-hidden ...">
              {recipe.instructions && recipe.instructions.length > 0
                ? recipe.instructions[0] + ".."
                : "No instructions available"}
            </span>
          </p>
          <p className="text-gray-700 mt-2">
            <strong>Servings:</strong> {recipe?.servings || "N/A"}
          </p>
          <p className="text-gray-700 mt-2">
            <strong>Total Time:</strong> {recipe?.total_time || "N/A"}
          </p>
        </div>
    </div>
  );
};

export default RecipeCard;

