import { useState } from "react";
import SearchInput from "../searchInput";
import { useRecipeBook } from "../../context/recipeBookContext";
import { RecipeSearchCard } from "../Recipe/recipeCard";

export default function SearchResults() {
  const [selectedIngredients, setSelectedIngredients] = useState<{}>(null);
  const { recipeBook } = useRecipeBook();

  return (
    <div className="bg-white py-8 sm:py-14">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:mx-0">
          <div className="p-2">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Ready to cook?
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Enter your ingredients or upload a photo to get started.
            </p>
          </div>
          <div>
            <SearchInput
              selectedIngredients={selectedIngredients}
              setSelectedIngredients={setSelectedIngredients}
            />
          </div>
        </div>

        <>
          <div className="flex w-full mt-4 pt-6 items-center justify-center">
            <hr className="flex-grow border-t border-gray-300" />
            {!recipeBook?.current?.loading ? (
              <span className="mx-4 text-gray-500 text-center text-sm">
                Search Results
              </span>
            ) : (
              <div className="mx-4 search_loader text-gray-500"></div>
            )}
            <hr className="flex-grow border-t border-gray-300" />
          </div>
          {recipeBook?.current?.recipes &&
            recipeBook?.current?.recipes.length > 0 && (
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 mt-5 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {recipeBook.current?.recipes.map((post, index) => (
                  <RecipeSearchCard index={index} key={post.id} recipe={post} />
                ))}
              </div>
            )}
        </>
      </div>
    </div>
  );
}
