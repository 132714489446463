import React, { FC, useRef, useState } from "react";
import FileResizer from "react-image-file-resizer";
import { CameraIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { RecipeActions, useRecipeBook } from "../../context/recipeBookContext";

type FileUploaderProps = {
  setIngredientImage: (image: any) => void;
  setFileName?: (name: string) => void;
  setImageMode?: (name: boolean) => void;
  isLoading: boolean;
};

const FileUploader: FC<FileUploaderProps> = ({
  setIngredientImage,
  setImageMode,
  setFileName,
  isLoading,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const { setRecipeBook, recipeBook } = useRecipeBook();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const resizeFile = (file) => {
    FileResizer.imageFileResizer(
      file,
      700, // new image max width
      700, // new image max height
      "JPEG", // default type
      100, // new image quality
      0, // rotation degree
      (uri) => {
        setRecipeBook(RecipeActions.SAVE_CURRENT_SEARCH, {
          name: file.name,
          byte: uri,
        });
      },
      "base64"
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFileName(files[0].name);
      resizeFile(files[0]);
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      setFileName(files[0].name);
      resizeFile(files[0]);
    }
  };

  const clearImage = () => {
    setFileName("");
    setIngredientImage(null);
    setRecipeBook(RecipeActions.SAVE_CURRENT_SEARCH, null);
    setImageMode(false);
  };

  if (isLoading) return null;

  return (
    <div
    className={`relative h-96 sm:w-96 md:w-8/12 border mb-4 rounded-lg flex items-center justify-center transition-colors duration-200 ${
      isDragging ? "border-blue-500 bg-gray-100" : "border-gray-300"
    }`}

      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {recipeBook?.search?.byte ? (
        <div className="relative h-full w-full">
          <img
            src={recipeBook?.search?.byte}
            alt="Uploaded"
            className="w-full h-full object-cover rounded-lg"
          />
          <button
            onClick={clearImage}
            className="absolute top-2 right-2 bg-white rounded-full p-1 shadow hover:bg-gray-100"
          >
            <XMarkIcon className="h-5 w-5 text-gray-500" />
          </button>
        </div>
      ) : (
        <>
          <button
            onClick={clearImage}
            className="absolute top-2 right-2 bg-white rounded-full p-1 shadow hover:bg-gray-100"
          >
            <XMarkIcon className="h-5 w-5 text-gray-500" />
          </button>
          <label htmlFor="image-upload" className="cursor-pointer">
            <div className="flex flex-col items-center space-y-2">
              <CameraIcon className="h-8 w-8 text-gray-400" />
              <p className="text-gray-500">
                Drag and drop or click to upload
              </p>
            </div>
          </label>
          <input
            type="file"
            id="image-upload"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept="image/*"
          />
        </>
      )}
    </div>
  );
};

export default FileUploader;
