import React, { useMemo, useRef, useState, useEffect } from "react";
import { Select, Spin } from "antd";
import type { SelectProps } from "antd";
import debounce from "lodash/debounce";
import { searchIngredient } from "../../services/firebase";
import { RecipeActions, useRecipeBook } from "../../context/recipeBookContext";
import { CameraIcon } from "@heroicons/react/24/outline";
import GenerateButton from "../generateButton";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  debounceTimeout?: number;
  changeMode: () => void;
  isLoading: boolean
}

export default function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({
  debounceTimeout = 400,
  value,
  changeMode,
  isLoading,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(undefined);
  const [options, setOptions] = useState<ValueType[]>([]);
  const { setRecipeBook } = useRecipeBook();
  const fetchRef = useRef(0);

  useEffect(() => {
    if (open === false) {
      setOpen(undefined);
    }
  },[open])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      
      if(!value) return;
      const searchText = value.toLowerCase().trim()
      
      searchIngredient(searchText).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(
          newOptions.map((option) => ({
            name: option.name,
            value: option.name,
          }))
        );
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout]);

  return (
    <Select
      virtual={true}
      labelInValue
      value={value}
      open={open}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      autoClearSearchValue={true}
      {...props}
      options={options}
      placeholder="Type here or select → 📸"
      mode="tags"
      style={{ width: "100%" }}
      placement="bottomLeft"
      suffixIcon={
        <CameraIcon
          className="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer z-50"
          onClick={changeMode}
        />
      }
      allowClear
      onClear={() => {
        setRecipeBook(RecipeActions.SAVE_CURRENT_SEARCH, null);
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <div className="m-2">
            <GenerateButton isLoading={isLoading} handler={() => setOpen(false)} />
          </div>
        </>
      )}
    />
  );
}
