import { FC } from "react";
import { Recipe } from "../../models/recipe.model";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Field, Input } from "@headlessui/react";

type TableProps = {
  recipes: Recipe[];
  handleSearch: (e) => void;
  searchTerm: string;
  isLoading: boolean;
};
const Table: FC<TableProps> = ({
  recipes,
  handleSearch,
  searchTerm,
  isLoading,
}) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="w-full max-w-md mb-4">
        <Field className="relative bg-gray-800">
          <div className="flex w-[30rem] rounded bg-gray-800">
            <Input
              type="search"
              className="w-full border-none bg-transparent px-4 py-1 text-white focus:outline-none"
              placeholder="search"
              value={searchTerm}
              onChange={handleSearch}
            />
            <button
              className={`flex items-center m-2 rounded px-4 py-2 font-semibold text-gray-100 bg-gray-500 cursor-not-allowed'6/9"`}
            >
              Search {isLoading && <div className=" ml-2 loader"></div>}
            </button>
          </div>
        </Field>
      </div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-16 py-3">
              Image
            </th>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Ingredients
            </th>
            <th scope="col" className="px-6 py-3">
              Instructions
            </th>
          </tr>
        </thead>
        <tbody>
          {recipes && recipes.length > 0 ? (
            recipes.map((recipe) => (
              <Disclosure key={recipe.id}>
                {({ open }) => (
                  <>
                    <tr
                      className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${
                        open ? "bg-gray-100" : ""
                      }`}
                    >
                      <td className="p-4 flex justify-center items-center">
                        <img
                          src={recipe.image || "/logo/psl-light.png"}
                          className="w-16 md:w-30 max-w-full max-h-full "
                          alt="Recipe has no image"
                        />
                      </td>
                      <td className="px-6 py-4 font-bold text-gray-900 dark:text-white">
                        {recipe.recipe_name}
                      </td>
                      <td className="px-6 py-4 mx-20">
                        {recipe.ingredients
                          ? recipe.ingredients.map((item) => (
                              <button
                                key={item.name}
                                className="bg-gray-300 m-2 text-gray-900 rounded-full py-1 px-3 text-sm/6 font-semibold  focus:outline-none data-[selected]:bg-white/10 data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white"
                              >
                                {item.name}
                              </button>
                            ))
                          : "No ingredients available"}
                      </td>
                      <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                        <DisclosureButton className="py-2">
                          {open ? "Hide Instruction" : "Show Instruction"}
                        </DisclosureButton>
                      </td>
                    </tr>

                    <DisclosurePanel as="tr" className="bg-gray-900">
                      <td colSpan={4} className="p-4">
                        <ol className="list-inside list-decimal">
                          {recipe?.instructions &&
                            recipe.instructions.map((instruction, index) => (
                              <li key={index} className="mb-2">
                                {instruction}
                              </li>
                            ))}
                        </ol>
                      </td>
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            ))
          ) : (
            <tr>
              <td>Sorry nothing to show here.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
