import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { applyActionCode } from 'firebase/auth';
import { auth } from '../../../configs/firebase';
import { LOGIN } from '../../../constant/routes';

const EmailVerified = () => {
  const [verificationStatus, setVerificationStatus] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const verifyEmailAction = async (oobCode) => {
      try {
        await applyActionCode(auth, oobCode);
        setVerificationStatus('success');
      } catch (error) {
        setVerificationStatus('success');
      }
    };

    const params = new URLSearchParams(location.search);
    const oobCode = params.get('oobCode');

    if (oobCode) {
      verifyEmailAction(oobCode);
    } else {
      setVerificationStatus('error');
    }
  }, [location]);

  return (
    <div className="h-full flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white p-6 md:p-12 rounded-lg shadow-lg text-center max-w mx-auto">
        {verificationStatus === 'success' && (
          <>
            <p className="text-lg md:text-2xl text-gray-600 mb-6">
              Email verified successfully, click below to login
            </p>
            <Link
              to={LOGIN}
              className="bg-gray-800 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 transition"
            >
              Login
            </Link>
          </>
        )}
        {verificationStatus === 'fail' && (
          <>
            <p className="text-lg md:text-2xl text-red-600 mb-6">
              Email verification failed. Please try again.
            </p>
            <Link
              to={LOGIN}
              className="bg-gray-800 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 transition"
            >
              Login
            </Link>
          </>
        )}
        {verificationStatus === 'error' && (
          <>
            <p className="text-lg md:text-2xl text-red-600 mb-6">
              An error occurred. Please try again later.
            </p>
            <Link
              to={LOGIN}
              className="bg-gray-800 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 transition"
            >
              Login
            </Link>
          </>
        )}
        {!verificationStatus && (
          <p className="text-lg md:text-2xl text-gray-600 mb-6">
            Verifying email...
          </p>
        )}
      </div>
    </div>
  );
};

export default EmailVerified;
