import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../configs/firebase';
import { useToaster } from '../../context/toasterContext';
import ToasterType from '../../models/toaster.enum';
import { LOGIN, FORGOT_PASSWORD } from '../../constant/routes';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidCode, setIsValidCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const toast = useToaster();

  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get('oobCode');

  useEffect(() => {
    const verifyCode = async () => {
      try {
        await verifyPasswordResetCode(auth, oobCode);
        setIsValidCode(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (oobCode) {
      verifyCode();
    } else {
      setLoading(false);
    }
  }, [oobCode, toast]);

  const resetPassword = async (e) => {
    e.preventDefault();
    const id = toast(ToasterType.LOADING, 'Resetting password...');

    if (password.length < 6) {
      toast(ToasterType.UPDATE, 'Password should be at least 6 characters.', {
        id,
        type: ToasterType.ERROR,
      });
      return;
    }

    if (password !== confirmPassword) {
      toast(ToasterType.UPDATE, "Passwords don't match. Please try again.", {
        id,
        type: ToasterType.ERROR,
      });
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, password);
      toast(
        ToasterType.UPDATE,
        'Password has been reset successfully. Redirecting to login...',
        {
          id,
          type: ToasterType.SUCCESS,
        }
      );

      setTimeout(() => navigate(LOGIN), 3000); // Redirect to login page after 3 seconds
    } catch (error) {
      toast(
        ToasterType.UPDATE,
        'Sorry, something went wrong. Please try again.',
        {
          id,
          type: ToasterType.ERROR,
        }
      );
    }
  };

  const redirectToForgotPassword = () => {
    navigate(FORGOT_PASSWORD);
  };

  return (
    <div className="flex items-center justify-center h-full">
      <form
        className="w-full max-w-md bg-white rounded-lg shadow-lg p-6"
        onSubmit={(e) => resetPassword(e)}
      >
        <div>
          <h1 className="font-bold text-center text-2xl mb-4">
            Reset Password
          </h1>
        </div>

        {loading && (
          <>
            <div className="mb-6 relative text-center">
              <label
                htmlFor="confirmPassword"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Screen processing please wait...
              </label>
            </div>
          </>
        )}

        {isValidCode && !loading && (
          <>
            <div className="mb-4 relative">
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                New Password
              </label>
              <div className="relative">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-10"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter new password"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-700 hover:text-gray-900 focus:outline-none"
                >
                  {!showPassword ? (
                    <EyeIcon className="h-5 w-5" />
                  ) : (
                    <EyeSlashIcon className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>
            <div className="mb-6 relative">
              <label
                htmlFor="confirmPassword"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Confirm Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-10"
                  id="confirmPassword"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-gray-800 hover:bg-black-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Reset Password
            </button>
          </>
        )}

        {!isValidCode && !loading && (
          <>
            <p className="text-red-600 mb-4">
              Ooh no! Invalid or expired password reset code. Please click the
              below button to regenerate new link
            </p>
            <button
              type="button"
              onClick={redirectToForgotPassword}
              className="w-full bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Click Here!
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default ResetPassword;
