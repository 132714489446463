function parseJsonString(str) {
  if (typeof str === "object" && str !== null) {
    return str;
  }

  if (typeof str !== "string") {
    throw new TypeError("Input must be a string");
  }

  try {
    const jsonObj = JSON.parse(str);
    return jsonObj;
  } catch (err) {
    throw new Error("Error parsing JSON:", err);
  }

  // Replace single quotes with double quotes in the JSON string
  // const sanitizedStr = str.replace(/'/g, '"');

  // TODO: This is slowing down the processor
  // const start = sanitizedStr.indexOf("{");
  // let count = 0;
  // let end = -1;

  // for (let i = start; i < sanitizedStr.length; i++) {
  //   if (sanitizedStr[i] === "{") {
  //     count++;
  //   } else if (sanitizedStr[i] === "}") {
  //     count--;
  //   }
  //   if (count === 0) {
  //     end = i;
  //     break;
  //   }
  // }

  // if (start !== -1 && end !== -1) {
  // const jsonStr = sanitizedStr.substring(start, end + 1);



  // else {
  //   throw new Error("Oops! We couldn’t cook up a recipe this time. 😬 Please regenerate or try again with a clearer image!");
  // }
}

export default parseJsonString;