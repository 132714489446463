import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../layout/Home';
import Auth from '../layout/Auth';
import {
  AUTHENTICATE,
  FORGOT_PASSWORD,
  GENERATOR,
  HOME,
  LOGIN,
  RECIPES,
  RESET_PASSWORD,
  SIGN_UP,
  VERIFY_EMAIL,
  ADMIN,
} from '../constant/routes';
import CookBook from '../layout/CookBook';
import RecipePage from '../layout/Recipe';
import ForgotPassword from '../component/forgotPassword';
import Verification from '../component/verifyEmail';
import NotFound from '../layout/404';
import ResetPassword from '../component/resetPassword';
import Login from '../component/login';
import Signup from '../component/signup';
import AuthGuard from './AuthGuard';
import Admin from '../layout/Admin';
import Generator from '../layout/Generator';

const newLocal = [
  {
    path: '/',
    Component: App,
    children: [
      {
        path: HOME,
        element: <Home />,
      },
      {
        path: GENERATOR,
        element: <Generator />,
      },
      {
        path: RECIPES,
        element: (
          <AuthGuard>
            <CookBook />
          </AuthGuard>
        ),
      },
      {
        path: `${RECIPES}/:id`,
        element: <RecipePage />,
      },
      {
        path: FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: LOGIN,
        element: <Login />,
      },
      {
        path: SIGN_UP,
        element: <Signup />,
      },
      {
        path: AUTHENTICATE,
        element: <Auth />,
      },
      {
        path: VERIFY_EMAIL,
        element: <Verification />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: ADMIN,
        element: (
          <AuthGuard>
            <Admin />
          </AuthGuard>
        ),
      },
    ],
  },
];

const routes = newLocal;

let router = createBrowserRouter(routes, {
  unstable_dataStrategy({ request, matches }) {
    return Promise.all(
      matches.map(async (match) => {
        console.log(`Processing route ${match.route.id}`);
        let result = await match.resolve();
        console.log(`Done processing route ${match.route.id}`);
        return result;
      })
    );
  },
});

export default router;
