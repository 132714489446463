import { UserInfo } from "firebase/auth";

export function transform(data: UserInfo) {
  return {
    uid: data.uid,
    displayName: data.displayName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    photoURL: data.photoURL,
  }
}