import { SchemaType } from "@google/generative-ai";

export const TEXT_PROMPT = (ingredients: string) => `{
	Analyze these given ingredients/meal: ${ingredients} and suggest up to 5 recipes, where given is a meal breakdown and provide 5 diff recipes/ways that can be followed to make the meal, including those needing extra ingredients. (with South African units using english language.),
	All ingredients that are not given but are need should be also added to the missing_ingredients array. Example Output:
}]`;

export const IMAGE_PROMPT = `{
	Analyze this image and suggest up to 5 recipes if image contains a meal provide 5 diff recipes/ways that can be followed to make the meal, including those needing extra ingredients. (with South African units using english language.),
	All ingredients that are not given but are need should be also added to the missing_ingredients array Example
}]`;


export const GENERATION_CONFIG = {
	responseMimeType: "application/json",
	temperature: 1,
	topP: 0.95,
	topK: 64,
	maxOutputTokens: 8192,
  responseSchema: {
    type: SchemaType.ARRAY,
    items: {
      type: SchemaType.OBJECT, 
      properties: {
        recipe_name: {
          type: SchemaType.STRING, 
          description: "Name of recipe"
        },
        ingredients: {
          type: SchemaType.ARRAY, 
          items: {
            type: SchemaType.OBJECT, 
            properties: {
              name: {
                type: SchemaType.STRING, 
                description: "Name of ingredient"
              },
              quantity: {
                type: SchemaType.STRING, 
                description: "Quantity of ingredient"
              },
              unit: {
                type: SchemaType.STRING, 
                description: "Unit of ingredient"
              }
            },
            required: [
              "name",
              "quantity"
            ]
          },
          description: "Ingredients needed for recipe"
        },
        missing_ingredients: {
          type: SchemaType.ARRAY, 
          items: {
            type: SchemaType.OBJECT, 
            properties: {
              name: {
                type: SchemaType.STRING, 
                description: "Name of ingredient"
              },
              quantity: {
                type: SchemaType.STRING, 
                description: "Quantity of ingredient"
              },
              unit: {
                type: SchemaType.STRING, 
                description: "Unit of ingredient"
              }
            },
            required: [
              "name",
              "quantity"
            ]
          },
          description: "Ingredients missing for recipe"
        },
        instructions: {
          type: SchemaType.ARRAY, 
          items: {
            type: SchemaType.STRING, 
            description: "Step by step instructions"
          },
          description: "Instructions for recipe"
        },
        servings: {
          type: SchemaType.STRING, 
          description: "Number of servings"
        },
        total_time: {
          type: SchemaType.STRING, 
          description: "Total time to make recipe"
        },
        vegan: {
          type: SchemaType.BOOLEAN, 
          description: "Whether or not recipe is vegan"
        },
        calories: {
          type: SchemaType.STRING, 
          description: "Number of calories in recipe"
        }
      },
      required: [
        "recipe_name",
        "ingredients",
        "missing_ingredients",
        "instructions",
        "servings",
        "total_time",
        "vegan",
        "calories"
      ]
    }
  }
};
  
