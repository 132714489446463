
export enum ToasterType {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  LOADING = 'loading',
  UPDATE = 'update'
}

export default ToasterType