import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useEffect, useState } from 'react';
import {
  getPagedRecipes,
  listenToRecipeWarehouseCount,
  listenToUsers,
  verifyAdmin,
} from '../services/firebase';
import { useToaster } from '../context/toasterContext';
import ToasterType from '../models/toaster.enum';
import { auth } from '../configs/firebase';
import Table from '../component/table';

export function StatsCard({ stats, loader }) {
  return (
    <div className="bg-white py-20 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-2">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-xs flex-col gap-y-4"
            >
              <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
              <dd className="flex justify-center items-center order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                {stat.value}{' '}
                <p
                  className={`text-[2.2rem] px-2 ${loader ? 'animate-pulse-fast' : ''}`}
                >
                  {stat.icon}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}

const RecipeTable = () => {
  const [recipes, setRecipes] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToaster();

  useEffect(() => {
    if (!recipes) {
      setIsLoading(true);
      getPagedRecipes(10, searchTerm).then(
        (res) => {
          setIsLoading(false);
          setRecipes(res.recipes);
        },
        (err) => {
          toast(ToasterType.ERROR, err.message);
          setIsLoading(false);
          setRecipes([]);
        }
      );
    }
  }, [recipes, searchTerm, toast]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setIsLoading(true);
    getPagedRecipes(20, e.target.value).then(
      (res) => {
        setIsLoading(false);
        setRecipes(res.recipes);
      },
      (err) => {
        toast(ToasterType.ERROR, err.message);
        setIsLoading(false);
        setRecipes([]);
      }
    );
  };

  return (
    <Table
      recipes={recipes || []}
      searchTerm={searchTerm || ''}
      handleSearch={handleSearch}
      isLoading={isLoading}
    />
  );
};

export function AdminLogin({ setAdmin }) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToaster();

  const login = async (event) => {
    event.preventDefault();
    const id = toast(ToasterType.LOADING, 'logging in...');
    setLoading(true);
    if (email) {
      try {
        const admin = await verifyAdmin(email);
        const currentUser = auth.currentUser;

        if (currentUser.email === email && admin.admin === true) {
          setAdmin(admin?.admin);
          toast(ToasterType.UPDATE, 'Login successful', {
            id,
            type: ToasterType.SUCCESS,
          });
        } else {
          setAdmin(false);
          toast(ToasterType.UPDATE, 'You are not allowed 🚪', {
            id,
            type: ToasterType.ERROR,
          });
        }
      } catch (error) {
        setLoading(false);

        toast(ToasterType.UPDATE, error.message, {
          id,
          type: ToasterType.ERROR,
        });
      }
    }
    setLoading(false);
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form action="#" onSubmit={login} className="space-y-6">
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email:
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                required
                autoComplete="email"
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <button
              disabled={loading}
              type="submit"
              className="flex w-full justify-center rounded-md bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default function Admin() {
  const [recipes, setRecipes] = useState(0);
  const [users, setUsers] = useState(0);
  const [admin, setAdmin] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const subscriptions = [];

    if (admin === true) {
      const unsubscribeRecipe = listenToRecipeWarehouseCount(
        setRecipes,
        setLoader
      );
      const unsubscribeUsers = listenToUsers(setUsers);
      subscriptions.push(unsubscribeRecipe);
      subscriptions.push(unsubscribeUsers);
    }

    return () => {
      if (subscriptions.length > 0) {
        subscriptions.forEach((sub) => sub());
      }
    };
  }, [admin]);

  if (admin === true) {
    return (
      <>
        <div className="min-h-full">
          <TabGroup className=" mx-auto">
            <TabList className="flex gap-4 bg-gray-800 p-5">
              <Tab className="rounded-full py-1 px-3 text-sm/6 font-semibold text-white focus:outline-none data-[selected]:bg-white/10 data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white">
                Dashboard
              </Tab>
              <Tab className="rounded-full py-1 px-3 text-sm/6 font-semibold text-white focus:outline-none data-[selected]:bg-white/10 data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white">
                Recipes
              </Tab>
            </TabList>
            <TabPanels className="p-3 bg-white shadow">
              <TabPanel>
                <StatsCard
                  loader={loader}
                  stats={[
                    {
                      id: 1,
                      name: 'Snapped recipes up to date',
                      value: recipes ? `${recipes}` : 0,
                      icon: '🍲',
                    },
                    {
                      id: 2,
                      name: 'Registered users up to date ',
                      value: users ? `${users}` : 0,
                      icon: '🧑‍🍳',
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel className="rounded-xl bg-white/5 p-3">
                <RecipeTable />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </>
    );
  }

  return <AdminLogin setAdmin={setAdmin}></AdminLogin>;
}
