import { Link } from 'react-router-dom';
import { HOME } from '../constant/routes';

const NotFound = () => {
  return (
    <div className="h-full flex items-center justify-center bg-gray-100 p-4">
      <div className="p-6 md:p-12 text-center max-w mx-auto">
        <h2 className="font-extrabold text-9xl text-gray-700">
          <span className="sr-only">Error</span>404
        </h2>
        <p className="text-lg md:text-2xl text-gray-600 mb-6">
          Oops! The page you're looking for doesn't exist.
        </p>
        <Link
          to={HOME}
          className="bg-gray-800 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 transition"
        >
          Go Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
