import { FC, useEffect, useState } from 'react';

import { useToaster } from '../../../context/toasterContext';
import ToasterType from '../../../models/toaster.enum';
import { saveRecipe } from '../../../services/firebase';
import { useUser } from '../../../context/userContext';
import { useRecipeBook } from '../../../context/recipeBookContext';
import stringSimilarity from 'string-similarity-js';
import MyModal from '../../modal';
import RecipeFullDetails from '../recipeFullDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN, RECIPES } from '../../../constant/routes';
import { BookmarkIcon } from '@heroicons/react/24/solid';
import { BookmarkIcon as BookmarkIconOutline } from '@heroicons/react/24/outline';
import { Recipe } from '../../../models/recipe.model';

type saveRecipeBtnProps = {
  recipe?: Recipe;
};
const SaveRecipeButton: FC<saveRecipeBtnProps> = ({ recipe }) => {
  const toast = useToaster();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [show, setShow] = useState(false);
  const [showNameDialog, setShowNameDialog] = useState(false);
  const [matching, setMatching] = useState([]);
  const { user } = useUser();
  const { recipeBook } = useRecipeBook();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (recipe && recipeBook?.recipes) {
      setSaved(!!recipeBook?.recipes.find((rec) => rec.id === recipe.id));
    }
  }, [recipe, recipeBook]);

  const saveRecipeHandler = () => {
    if (saved) {
      navigate(RECIPES);
      return;
    }

    if (!user?.uid) {
      navigate(LOGIN, { state: { from: location.pathname } });
      return;
    }

    if (!recipeBook?.current && !recipe) {
      return toast(ToasterType.ERROR, 'No recipe to save');
    }

    if (!recipe) {
      const matching = [];
      if (recipeBook?.recipes?.length > 0) {
        recipeBook?.recipes.forEach((_recipe) => {
          const score = stringSimilarity(
            recipeBook.current?.instructions.toString(),
            _recipe.instructions.toString()
          );

          if (score > 0.7) {
            matching.push(_recipe);
          }
        });
      }

      if (matching.length > 0) {
        setMatching(matching);
        setShow(true);
        return;
      }
    }
    onSaveRecipe();
  };

  const onSaveRecipe = async () => {
    if (saving) return;
    setSaving(true);

    if (!recipeBook?.current?.id && !recipe) {
      toast(ToasterType.ERROR, 'Recipe to save data not found');
    }

    const id = toast(ToasterType.LOADING, 'Saving recipe...');

    saveRecipe(user.uid, recipe ? recipe.id : recipeBook.current.id).then(
      (res) => {
        toast(ToasterType.UPDATE, 'Recipe saved successful', {
          id,
          type: ToasterType.SUCCESS,
          actionHandler: () => {
            console.log('view list');
          },
        });
        resetModal();
      },
      (err) => {
        toast(ToasterType.UPDATE, err.message, {
          id,
          type: ToasterType.ERROR,
        });
        resetModal();
      }
    );
  };

  const resetModal = () => {
    setSaving(false);
    setShow(false);
    setShowNameDialog(false);
  };

  return (
    <>
      {saved ? (
        <button
          key="saved"
          onClick={saveRecipeHandler}
          className="flex items-center justify-center m-2 px-4 py-2 bg-[#fff4ed] rounded font-bold text-black hover:opacity-80 transition"
        >
          <BookmarkIcon className="w-5 mr-0 sm:mr-2 bold" />
          <span className="sm:block hidden">Cookbook</span>
        </button>
      ) : (
        <button
          key="save"
          onClick={saveRecipeHandler}
          disabled={saving}
          className="flex items-center justify-center m-2 px-4 py-2 bg-[#fff4ed] rounded font-bold text-black hover:opacity-80 transition"
        >
          <BookmarkIconOutline className="w-5 mr-0 sm:mr-2 bold" />
          <span className="sm:block hidden">Save</span>
        </button>
      )}

      <MyModal
        title="Compare recipe"
        open={show}
        description="There is already a recipe similar to the one you are trying to save, please see below:"
        okButtonTitle="Save Anyway"
        disableActions={saving}
        key="compare"
        okAction={() => {
          onSaveRecipe();
        }}
        cancelAction={resetModal}
      >
        <div className="grid sm:grid-cols-3 gap-4 max-w-screen-md">
          {matching.map((item, index) => (
            <div
              className="max-w-72"
              key={`${item?.recipe_name}-${index * 123}`}
            >
              <RecipeFullDetails
                recipe={item}
                permission={{ noActions: true }}
              />
            </div>
          ))}
        </div>
      </MyModal>

      <MyModal
        title="Customize recipe"
        open={showNameDialog}
        okButtonTitle="Save"
        key="recipe"
        okAction={() => {
          onSaveRecipe();
        }}
        disableActions={saving}
        cancelAction={() => {
          setShowNameDialog(false);
        }}
      >
        <div className="mt-2">
          <RecipeFullDetails recipe={recipeBook?.current} />
        </div>
      </MyModal>
    </>
  );
};

export default SaveRecipeButton;
