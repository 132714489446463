import { createContext, useContext } from 'react';
import {
  Bounce,
  ToastContainer,
  ToastOptions,
  UpdateOptions,
  toast,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToasterType from '../models/toaster.enum';

export const ToasterContext = createContext(null);

export const ToasterProvider = ({ children }) => {
  const handleToast = (
    type: ToasterType,
    message: string,
    config?: ToastOptions | UpdateOptions,
  ) => {
    const defaultConfig = {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      transition: Bounce,
      ...config,
    };

    if (type === ToasterType.UPDATE) {
      return toast.update(config['id'], {
        render: message,
        isLoading: false,
        ...(defaultConfig as UpdateOptions),
      });
    }

    return toast[type](message);
  };

  return (
    <ToasterContext.Provider value={handleToast}>
      <ToastContainer/>
      {children}
    </ToasterContext.Provider>
  );
};

export const useToaster = () => useContext(ToasterContext);
