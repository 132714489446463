import { useEffect, useState } from 'react';

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import Capture from '../component/capture';
import FileUploader from '../component/upload';
import mockImage from '../constant/mockImage';
import parseJsonString from '../helpers/parseJson';
import { RecipeActions, useRecipeBook } from '../context/recipeBookContext';
import Loader from '../component/loader';
import RecipeFullDetails from '../component/Recipe/recipeFullDetails';
import { useUser } from '../context/userContext';
import { useSearchParams } from 'react-router-dom';
import { saveRecipeWithImage } from '../services/firebase';
import { IMAGE_PROMPT } from '../configs/prompt';
import { genAImodel } from '../configs/firebase';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Generator() {
  const [ingredientImage, setIngredientImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { recipeBook, setRecipeBook } = useRecipeBook();
  const user = useUser();
  const recipeGenerated = recipeBook?.current || ingredientImage;
  let [categories] = useState(['Upload', 'Capture']);
  let [searchParams, setSearchParams] = useSearchParams();

  let tab = searchParams.get('tab');

  useEffect(() => {
    if (!ingredientImage && recipeBook?.current) {
      setIngredientImage(recipeBook.current.image);
    }
  }, [ingredientImage, recipeBook]);

  const processImage = async () => {
    let imageData = ingredientImage || mockImage;

    if (imageData) {
      setIsLoading(true);
      setError(null); 

      const queryImage = {
        inlineData: {
          data: imageData.split(',')[1],
          mimeType: 'image/jpeg',
        },
      };

      genAImodel
        .generateContent([IMAGE_PROMPT, queryImage])
        .then(async (res) => {
          const resRecipe = parseJsonString(res.response.text());

          if (resRecipe) {
            const data = {
              ...resRecipe,
              image: ingredientImage,
              scannedById: user?.user?.uid || null,
            };

            const saved = await saveRecipeWithImage(data);
            setRecipeBook(RecipeActions.SAVE_CURRENT_RECIPE, saved || data);
          }

          setIsLoading(false);
        })
        .catch((err) => {
          setError("Couldn't generate recipe, Technical error");
          console.log(err);

          setIsLoading(false);
          setRecipeBook(RecipeActions.RESET_CURRENT);
        });
    }
  };

  const resetAll = (): void => {
    setIngredientImage(null);
    setRecipeBook(RecipeActions.SAVE_CURRENT_RECIPE, null);
    setError(null);
  };

  return (
    <main className="flex-grow flex flex-col items-center  w-full max-w-3xl mx-auto p-6">
      <div className="w-full max-w px-2 sm:px-0">
        {!recipeGenerated && (
          <TabGroup selectedIndex={Number(tab)}>
            <TabList className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
              {categories.map((category, index) => (
                <Tab
                  onClick={() => setSearchParams({ tab: index.toString() })}
                  key={category}
                  disabled={!!recipeGenerated}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white/60 ring-offset-2 ring-offset-gray-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-gray-800 text-white shadow'
                        : 'text-black hover:bg-white/[0.12] hover:text-gray',
                      !!recipeGenerated
                        ? 'text-white shadow cursor-no-drop opacity-30 transition'
                        : ''
                    )
                  }
                >
                  {category}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel className={"w-full"}>
                {!recipeBook?.current && !isLoading && (
                  <FileUploader
                    setIngredientImage={setIngredientImage}
                    isLoading={isLoading}
                  />
                )}
              </TabPanel>
              <TabPanel>
                <>
                  {!recipeBook?.current && !isLoading && (
                    <Capture
                      setError={setError}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      setIngredientImage={setIngredientImage}
                    />
                  )}
                </>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        )}

        {/* Loading spinner */}
        {isLoading && <Loader />}

        {/* Display detection results */}
        {!isLoading && (
          <div className="w-full mt-6">
            <RecipeFullDetails
              recipe={recipeBook?.current}
              defaultExpand={true}
              onResetImageHandler={resetAll}
              image={ingredientImage || null}
              permission={{ home: true }}
              processImageHandler={processImage}
            />
          </div>
        )}

        {/* Capture button */}
        {!isLoading && ingredientImage && !recipeBook?.current && (
          <div className={`flex mt-4`}>
            <button
              onClick={processImage}
              className={`w-full px-4 py-2 rounded bg-gray-800 text-white hover:opacity-80 transition`}
            >
              {'Generate recipe'}
            </button>
          </div>
        )}

        {error && !isLoading && (
          <div className="mt-6 text-center">
            <h2>{error}</h2>
          </div>
        )}
      </div>
    </main>
  );
}

export default Generator;
