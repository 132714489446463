import { useEffect, useState } from 'react';
import { auth } from '../../configs/firebase';
import { sendEmailVerification } from 'firebase/auth';
import { useToaster } from '../../context/toasterContext';
import ToasterType from '../../models/toaster.enum';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN } from '../../constant/routes';

const Verification = () => {
  const [emailSent, setEmailSent] = useState(false);
  const toast = useToaster();
  const navigate = useNavigate();
  const { state } = useLocation();
  const email = state?.email ?? '';

  useEffect(() => {
    if (!email) {
      navigate(LOGIN);
    }
  });

  const resendVerificationEmail = async () => {
    const id = toast(ToasterType.LOADING, 'Verifying email ...');

    if (auth.currentUser) {
      try {
        await sendEmailVerification(auth.currentUser);
        toast(ToasterType.UPDATE, 'Verification email sent!', {
          id,
          type: ToasterType.SUCCESS,
        });
        setEmailSent(true);
      } catch (error) {
        console.error('Error sending verification email:', error);
        toast(ToasterType.ERROR, 'Error sending verification email.');
      }
    }
  };

  return (
    <div className="w-full m-auto  max-w-md bg-white rounded-lg shadow-lg p-6">
      <h1 className="font-bold text-center text-2xl mb-4">Verify Your Email</h1>
      <p className="mb-4">
        A verification email has been sent to your email address (
        <strong>{email}</strong>). Please check your inbox and click the
        verification link to verify your account.
      </p>
      <button
        className="w-full bg-gray-800 hover:bg-black-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        onClick={resendVerificationEmail}
        disabled={emailSent}
      >
        {emailSent ? 'Verification Email Sent' : 'Resend Verification Email'}
      </button>
      <button
        className="w-full bg-gray-800 hover:bg-black-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
        onClick={() => navigate(LOGIN)}
      >
        Back to Login
      </button>
    </div>
  );
};

export default Verification;
