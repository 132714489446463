import { Recipe } from '../../../models/recipe.model';
import { FC } from 'react';
import { BookOpenIcon } from '@heroicons/react/20/solid';
import RecipeCard from '../recipeCard';

const EmptyCookbookMessage = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="max-w-md mx-auto text-center">
        <BookOpenIcon className="w-52 h-52 mx-auto mt-10 text-gray-400 mb-4" />{' '}
        {/* Icon */}
        <p className="text-gray-800 text-lg">
          <strong>Oops! Your cookbook is empty...</strong> <br />
          Add some recipes and spice up your kitchen adventures!
        </p>
      </div>
    </div>
  );
};

const RecipeList: FC<{ recipes: Recipe[]; refreshList?: () => void }> = ({
  recipes,
  refreshList,
}) => {
  if (!recipes || recipes?.length === 0) {
    return <EmptyCookbookMessage />;
  }
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {recipes.map((recipe, index) => (
        <RecipeCard
          index={index}
          key={recipe.id}
          recipe={recipe}
          refreshList={refreshList}
        />
      ))}
    </div>
  );
};

export default RecipeList;
