import { Outlet } from 'react-router-dom';
import Navbar from './component/navbar';
import { ToasterProvider } from './context/toasterContext';
import { UserProvider } from './context/userContext';
import { RecipeBookProvider } from './context/recipeBookContext';
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

const App = () => {
  return (
    <HelmetProvider context={helmetContext}>
      <ToasterProvider>
        <UserProvider>
          <RecipeBookProvider>
            <Navbar>
              <Outlet />
            </Navbar>
          </RecipeBookProvider>
        </UserProvider>
      </ToasterProvider>
    </HelmetProvider>
  );
};
export default App;
