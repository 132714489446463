import { useEffect, useState } from 'react';
import { auth } from '../../configs/firebase';
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToaster } from '../../context/toasterContext';
import { useUser } from '../../context/userContext';
import ToasterType from '../../models/toaster.enum';
import {
  FORGOT_PASSWORD,
  HOME,
  SIGN_UP,
  VERIFY_EMAIL,
} from '../../constant/routes';
import { getData } from '../../services/firebase';
import { User } from '../../models/user.model';
import { Field, Input, Label } from '@headlessui/react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notice, setNotice] = useState('');
  const toast = useToaster();
  const { user, setUser } = useUser();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate(HOME, { replace: true });
    }
  });

  const loginWithUsernameAndPassword = async (e) => {
    e.preventDefault();
    const id = toast(ToasterType.LOADING, 'logging in...');
    setLoading(true);
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);

      if (res.user.emailVerified) {
        const data = await getData('users', res.user.uid);
        setUser(data.data() as User);
        toast(ToasterType.UPDATE, 'Login successful', {
          id,
          type: ToasterType.SUCCESS,
        });

        navigate(location?.state?.from || HOME);
        setLoading(false);
      } else {
        await sendEmailVerification(auth.currentUser);
        navigate(VERIFY_EMAIL, { state: { email } });
        toast(ToasterType.UPDATE, 'Please verify email', {
          id,
          type: ToasterType.INFO,
        });
        setLoading(false);
      }
    } catch (error) {
      setNotice('You entered a wrong username or password.');
      toast(ToasterType.UPDATE, 'Login failed', {
        id,
        type: ToasterType.ERROR,
      });
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center flex-col pt-4 m-4">
      <form className="w-full max-w-md bg-white rounded-lg shadow-lg p-6">
        {'' !== notice && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <span className="block sm:inline">{notice}</span>
          </div>
        )}
        <div>
          <h1 className="font-bold text-center text-2xl mb-4">LOGIN</h1>
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Email address
          </label>
          <input
            type="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <Field className="mb-6 relative">
          <Label
            htmlFor="password"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Password
          </Label>
          <Input
            type={showPassword ? 'text' : 'password'}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute top-9 right-0 flex items-center px-3 text-gray-700 hover:text-gray-900 focus:outline-none"
          >
            {!showPassword ? (
              <EyeIcon className="h-5 w-5" />
            ) : (
              <EyeSlashIcon className="h-5 w-5" />
            )}
          </button>
        </Field>
        <button
          type="submit"
          disabled={loading}
          className="w-full bg-gray-800 hover:bg-black-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={(e) => loginWithUsernameAndPassword(e)}
        >
          Login
        </button>

        <div className="text-center mt-4">
          Need to sign up for an account?{' '}
          <p
            onClick={() => {
              navigate(SIGN_UP);
            }}
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Click here.
          </p>
        </div>
        <div className="text-center mt-1">
          <p
            className="text-blue-500 hover:underline cursor-pointer"
            onClick={() => {
              navigate(FORGOT_PASSWORD);
            }}
          >
            Forgot Password?
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
