import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import {
  ArrowLeftStartOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  BookOpenIcon,
  ChevronDownIcon,
  UserCircleIcon,
} from '@heroicons/react/20/solid';
import { useUser } from '../../context/userContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HOME, LOGIN, RECIPES } from '../../constant/routes';
import { CameraIcon } from '@heroicons/react/24/outline';

export default function ProfileMenu() {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (!user)
    return (
      <div>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button
              className="text-gray-300 hover:bg-gray-700 bg-gray-700 hover:text-white inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium hover:bg-black/30"
              onClick={() => navigate(LOGIN)}
            >
              <ArrowRightStartOnRectangleIcon className="m-auto h-6 w-6 text-sm pr-1" />
              Login
            </Menu.Button>
          </div>
        </Menu>
      </div>
    );

  return (
    <div className="ml-4 flex items-center md:ml-6">
      <div>
        {pathname === RECIPES ? (
          <Link
            to={HOME}
            type="button"
            className="flex w-full mr-4 justify-center rounded-md bg-gray-700 px-4 py-2 text-sm font-medium text-white hover:bg-black/85"
          >
            <CameraIcon className="mr-2 h-5 w-5" />
            <span>Generate new recipe</span>
          </Link>
        ) : (
          <Link
            to={RECIPES}
            type="button"
            className="flex w-full mr-4 justify-center px-4 py-2 text-sm font-medium text-white  hover:underline"
          >
            <BookOpenIcon className="mr-2 h-5 w-5" />
            <span>View My Cookbook</span>
          </Link>
        )}
      </div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md bg-gray-700 px-4 py-2 text-sm font-medium text-white hover:bg-black/85">
            <UserCircleIcon
              className="mr-2 ml-1 h-5 w-5 text-sm"
              aria-hidden="true"
            />
            <span className="hidden sm:inline">
              {user?.displayName || user?.email || 'User'}
            </span>
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5 hidden sm:inline"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-black/10`}
                    onClick={() => navigate(RECIPES)}
                  >
                    <BookOpenIcon className="mr-2 h-5 w-5" />
                    My Recipes
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-black/10`}
                    onClick={logout}
                  >
                    <ArrowLeftStartOnRectangleIcon className="mr-2 h-5 w-5" />
                    Logout
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
