import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  addDoc,
  where,
  Timestamp,
  getCountFromServer,
  writeBatch,
  onSnapshot,
  startAfter,
  limit,
} from "firebase/firestore";
import { db } from "../configs/firebase";
import { RECIPE_WAREHOUSE_TABLE, USERS_TABLE } from "../constant/table";
import { MAX_SAVED_RECIPE } from "../constant";
import { Recipe } from "../models/recipe.model";

export function listenToUsers(setUsers: (users) => void) {
  try {
    const recipesCollectionRef = collection(db, USERS_TABLE);

    const unsubscribe = onSnapshot(recipesCollectionRef, (snapshot) => {
      setUsers(snapshot.size);
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error listening to Users:", error);
    throw error;
  }
}

export function getData(document, documentId?: string) {
  const docRef = doc(db, document, documentId);
  return getDoc(docRef);
}

export async function saveRecipeWithImage(data: Recipe[]) {
  try {
    const batch = writeBatch(db);
    const newRecipeDocRefs = [];

    const newData = data.map((item) => ({
      ...item,
      createdAt: Timestamp.now(),
    }));

    // Add new recipes and images to the batch
    for (const recipe of newData) {
      const newRecipeDocRef = doc(collection(db, RECIPE_WAREHOUSE_TABLE));
      batch.set(newRecipeDocRef, recipe);
      newRecipeDocRefs.push(newRecipeDocRef); // Store the reference
    }

      await batch.commit();
      // Get the IDs of the newly created recipes
      const recipeIds = newRecipeDocRefs.map((ref) => ref.id);

    // Use a where query to fetch the recipe documents
    const recipesQuery = query(
      collection(db, RECIPE_WAREHOUSE_TABLE),
      where("__name__", "in", recipeIds)
    );
    const querySnapshot = await getDocs(recipesQuery);

    // Extract the recipe dataÍÍ
    const savedRecipes = querySnapshot.docs.map((doc) => { 
      return {
      id: doc.id,
      ...(doc.data() as Recipe),
    }});

    return savedRecipes;
  } catch (error) {
    throw new Error("Error saving data to Firestore");
  }
}

export async function saveData(collectionName, documentId = null, data) {
  try {
    let res = null;
    const collectionRef = collection(db, collectionName);
    const newData = {
      ...data,
      createdAt: Timestamp.now(),
    };

    if (documentId) {
      res = await setDoc(doc(collectionRef, documentId), newData);
    } else {
      res = await addDoc(collectionRef, newData);
    }

    return res;
  } catch (error) {
    throw new Error("Error saving data to Firestore");
  }
}

export function updateData(document: string, data: any, documentId?: string) {
  const collectionRef = collection(db, document);
  const docRef = documentId
    ? doc(collectionRef, documentId)
    : doc(collectionRef);
  return updateDoc(docRef, data);
}

export async function saveRecipe(userId: string, recipeId: string) {
  try {
    // Reference to the user's document
    const userDocRef = doc(db, "users", userId);

    // Reference to the "recipes" subcollection
    const recCollectionRef = collection(userDocRef, "recipes");

    //before adding doc count number of existing docs in the collection
    const countSnapshot = await getCountFromServer(recCollectionRef);
    //if collection greater than 20 throw error
    if (countSnapshot.data().count >= MAX_SAVED_RECIPE) {
      throw new Error("You can only save 20 recipes");
    }

    // Reference to the specific recipe document
    const recipeDocRef = doc(recCollectionRef, recipeId);

    // Set the document with recipeId and createdAt fields
    return await setDoc(recipeDocRef, {
      recipeId: recipeId,
      createdAt: Timestamp.now(),
    });
  } catch (error) {
    throw error; // Re-throw the error after logging it
  }
}

export async function deleteRecipe(userId: string, recipeId: string) {
  try {
    const q = query(
      collection(db, "users", userId, "recipes"),
      where("recipeId", "==", recipeId)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot?.docs[0]?.id) {
      throw Error("Failed to delete recipe");
    }

    return await deleteDoc(querySnapshot.docs[0].ref);
  } catch (error) {
    throw error;
  }
}

export function getRecipesByUserId(userId: string, setData: (recipes) => void) {
  try {
    const q = query(collection(db, "users", userId, "recipes"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const userRecipeList = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const myRecipe = userRecipeList
        .map((recipe) => recipe["recipeId"])
        .filter((item) => !!item);

      if (myRecipe?.length > 0) {
        const warehouseQuery = query(
          collection(db, RECIPE_WAREHOUSE_TABLE),
          where("__name__", "in", myRecipe),
          orderBy("createdAt", "desc")
        );

        getDocs(warehouseQuery).then((snapshot) => {
          const scannedRecipes = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          setData(scannedRecipes);
        });
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error fetching recipes:", error);
    throw error;
  }
}

export async function getRecipeById(recipeId: string) {
  try {
    const recipeRef = doc(db, RECIPE_WAREHOUSE_TABLE, recipeId);
    const recipeDoc = await getDoc(recipeRef);

    if (recipeDoc.exists()) {
      return { ...recipeDoc.data(), id: recipeDoc.id };
    } else {
      throw new Error("Recipe not found");
    }
  } catch (error) {
    console.error("Error fetching recipe:", error);
    throw error;
  }
}

/**
 * ADMIN
 */
export async function verifyAdmin(email: string) {
  try {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);

    return await querySnapshot?.docs[0]?.data();
  } catch (error) {
    throw error;
  }
}

export function listenToRecipeWarehouseCount(
  setRecipes: (recipes) => void,
  setLoader: (loader: boolean) => void
) {
  try {
    const recipesCollectionRef = collection(db, RECIPE_WAREHOUSE_TABLE);
    setLoader(true);

    const unsubscribe = onSnapshot(
      recipesCollectionRef,
      (snapshot) => {
        // const recipes = snapshot.docs.map((doc) => ({
        //   ...doc.data(),
        //   id: doc.id,
        // }));
        setLoader(false);
        setRecipes(snapshot.size);
      },
      () => {
        setLoader(false);
      }
    );

    return unsubscribe;
  } catch (error) {
    console.error("Error listening to recipe warehouse:", error);
    throw error;
  }
}

export async function getPagedRecipes(
  pageSize: number,
  searchQuery?: string,
  lastVisibleDocument?: any
) {
  try {
    const recipesCollectionRef = collection(db, RECIPE_WAREHOUSE_TABLE);
    let querySnapshot;

    if (lastVisibleDocument) {
      // Paginate if lastVisibleDocument is provided
      const nextQuery = query(
        recipesCollectionRef,
        orderBy("createdAt", "desc"),
        startAfter(lastVisibleDocument),
        limit(pageSize)
      );
      querySnapshot = await getDocs(nextQuery);
    } else {
      // First page
      querySnapshot = await getDocs(
        query(
          recipesCollectionRef,
          orderBy("createdAt", "desc"),
          limit(pageSize)
        )
      );
    }

    if (searchQuery) {
      // Prepare the query to search within the flattened ingredients field
      const ingredientsQuery = query(
        recipesCollectionRef,
        orderBy("createdAt", "desc"),
        where("ingredients_flat", "array-contains", searchQuery),
        limit(pageSize)
      );

      querySnapshot = await getDocs(ingredientsQuery);
    }

    const recipes = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return {
      recipes,
      lastVisibleDocument:
        querySnapshot.docs.length > 0
          ? querySnapshot.docs[querySnapshot.docs.length - 1]
          : null,
    };
  } catch (error) {
    console.error("Error fetching recipes:", error);
    throw error;
  }
}

export async function searchIngredient(searchText: string) {
  try {
    const ingredientsRef = collection(db, "ingredients");
    const q = query(
      ingredientsRef,
      where("name", ">=", searchText),
      where("name", "<=", searchText + "\uf8ff")
    );

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error searching ingredients:", error);
  }
}
