import { Disclosure, Menu } from '@headlessui/react';
import {
  ArrowLeftStartOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  Bars3Icon,
  BellIcon,
  BookOpenIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import ProfileMenu from '../profileMenu';
import { useUser } from '../../context/userContext';
import Footer from '../footer';
import { Link, useNavigate } from 'react-router-dom';
import { LOGIN, RECIPES } from '../../constant/routes';

const navigation = [];
const userNavigation = [
  {
    name: 'My Cookbook',
    href: RECIPES,
    icon: <BookOpenIcon className="mr-2 ml-1 h-6 w-6 text-white" />,
  },
  {
    name: 'Logout',
    href: 'logout',
    icon: (
      <ArrowLeftStartOnRectangleIcon className="mr-2 ml-1 h-6 w-6 text-white" />
    ),
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar({ children }) {
  const { user, logout } = useUser();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-10">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <Link to="/" className="flex items-center">
                      <img
                        src="/logo/psl-light-logo.png"
                        alt="PulseSync Logo"
                        className="h-11 w-auto"
                        title="PulseSync Logo"
                      />
                    </Link>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'rounded-md px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button
                        type="button"
                        className="hidden mr-5 relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                      <ProfileMenu />
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    {user ? (
                      <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    ) : (
                      <div>
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button
                              className="text-gray-300 hover:bg-gray-700 bg-gray-700 hover:text-white inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium hover:bg-black/30"
                              onClick={() => navigate(LOGIN)}
                            >
                              <ArrowRightStartOnRectangleIcon className="m-auto h-6 w-6 text-sm pr-1" />
                              Login
                            </Menu.Button>
                          </div>
                        </Menu>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {user && (
                <Disclosure.Panel className="md:hidden">
                  <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block rounded-md px-3 py-2 text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="border-t border-gray-700 pb-3 pt-4">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <UserCircleIcon
                          className="mr-2 ml-1 h-6 w-6 text-sm text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">
                          {user.name}
                        </div>
                        <div className="text-sm font-medium leading-none text-gray-400">
                          {user.email}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {userNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          onClick={
                            item.href === 'logout'
                              ? logout
                              : () => navigate(item.href)
                          }
                          className="flex items-center rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          <div className="flex-shrink-0">
                            {item?.icon && item.icon}
                          </div>
                          <span className="ml-3">{item.name}</span>
                        </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>

        <main className="flex-grow flex sm:p-8 ">
          <div className="w-full mx-auto max-w-7xl">{children}</div>
        </main>
        <Footer />
      </div>
    </>
  );
}
