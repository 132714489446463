import { useEffect, useState } from 'react';
import { useUser } from '../context/userContext';
import Loader from '../component/loader';
import { Recipe } from '../models/recipe.model';
import { getRecipeById } from '../services/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import RecipeFullDetails from '../component/Recipe/recipeFullDetails';
import { useToaster } from '../context/toasterContext';
import ToasterType from '../models/toaster.enum';
import Head from '../component/Head';

function RecipePage() {
  const [recipe, setRecipe] = useState<Recipe | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToaster();
  const { user } = useUser();

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const recipe = await getRecipeById(params.id);
        setRecipe(recipe as Recipe);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast(ToasterType.ERROR, error.message);
        console.error('Error fetching recipes:', error);
      }
    };

    fetchRecipe();
  }, [user, params, toast]);

  const handleGoBack = () => {
    if (navigate.length > 1) {
      navigate(-1); 
    } else {
      navigate('/');
    }
  };

  return (
    <div className="container mx-auto p-8 min-h-screen">
      <Head
        title={
          recipe?.recipe_name
            ? `${recipe.recipe_name} | Pulsesync Cooking`
            : 'Pulsesync Cooking'
        }
        description="Discover delicious recipes instantly! Scan or upload ingredients with our innovative web app. Combining AI and image recognition for a seamless cooking experience."
        keywords={
          recipe?.ingredients?.map((i) => i.name).join(', ') ||
          'recipe, cooking, ingredients, scanner, generator, web app, food, cuisine'
        }
        image={`https://firebasestorage.googleapis.com/v0/b/icookers-8f96a.appspot.com/o/recipes%2F${params?.id}?alt=media`}
      />
      <nav className="mb-4">
          <button
            onClick={handleGoBack}
            className="text-gray-400 font-bold hover:underline"
          >
            &larr; Back
          </button>
      </nav>
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <RecipeFullDetails recipe={recipe} defaultExpand={true} />
      )}
    </div>
  );
}

export default RecipePage;
