import { Field } from "@headlessui/react";
import { FC, useEffect, useRef, useState } from "react";
import GenerateButton from "../generateButton";
import { RecipeActions, useRecipeBook } from "../../context/recipeBookContext";
import FileUploader from "../upload";
import DebounceSelect from "./v2";

type SearchInputProps = {
  selectedIngredients: any;
  setSelectedIngredients: (items) => void;
};

const SearchInput: FC<SearchInputProps> = ({
  selectedIngredients,
  setSelectedIngredients,
}) => {
  const [_, setValue] = useState("");
  const [isLoading] = useState(false);
  const { setRecipeBook, recipeBook } = useRecipeBook();
  const ingredientListRef = useRef(null);
  const [file, setFile] = useState(null);
  const [imageMode, setImageMode] = useState(false);

  /**
   * For retrieving search items from storage
   */
  useEffect(() => {
    if (!file && recipeBook?.search?.name) {
      setFile(recipeBook?.search["byte"]);
      setImageMode(true);
      setSelectedIngredients(null);
      return;
    }

    if (
      !file &&
      (!selectedIngredients || selectedIngredients.length === 0) &&
      recipeBook?.search
    ) {
      setSelectedIngredients(recipeBook?.search);
    }
  }, [recipeBook, file, selectedIngredients, setSelectedIngredients]);

  const clearAll = () => {
    setSelectedIngredients(null);
    setRecipeBook(RecipeActions.SAVE_CURRENT_SEARCH, null);
  };

  return (
    <div className="w-full max-w relative sm:w-8/12 items-center">
      <div ref={ingredientListRef}>
        {imageMode ? (
          <>
            <FileUploader
              setIngredientImage={setFile}
              isLoading={isLoading}
              setFileName={setValue}
              setImageMode={setImageMode}
            />
            <div className="sm:w-8/12">
              <GenerateButton isLoading={isLoading} />
            </div>
          </>
        ) : (
          <>
            <div>
              <Field className="relative m-2 h-full">
                <DebounceSelect
                  mode="multiple"
                  value={selectedIngredients}
                  placeholder="Select users"
                  onChange={(val) => {
                    setSelectedIngredients(val);
                    setRecipeBook(RecipeActions.SAVE_CURRENT_SEARCH, val);
                  }}
                  style={{ width: "100%" }}
                  changeMode = {() => {
                    setImageMode(true);
                    clearAll();
                  }}
                  isLoading={isLoading}
                />
              </Field>
              <div className="m-2">
                <GenerateButton isLoading={isLoading} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default SearchInput;
