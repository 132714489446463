import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Recipe } from "../../../models/recipe.model";
import { FC } from "react";
import SaveRecipeButton from "../saveRecipe";
import { ArrowPathIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useUser } from "../../../context/userContext";
import RecipeOptions from "../recipeOption";

type RecipeFullDetailsProps = {
  recipe: Recipe;
  image?: string;
  defaultExpand?: boolean;
  onResetImageHandler?: () => void;
  hideAllActions?: boolean;
  processImageHandler?: () => void;
  permission?: {
    noActions?: boolean;
    home?: true;
    book?: true;
  };
};
const RecipeFullDetails: FC<RecipeFullDetailsProps> = ({
  recipe,
  onResetImageHandler,
  defaultExpand = false,
  permission = {},
  image,
  processImageHandler,
}) => {
  const { user } = useUser();
  const hasIngredients =
    recipe && recipe.ingredients && recipe.ingredients.length > 0;
  const hasInstructions =
    recipe && recipe.instructions && recipe.instructions.length > 0;

  if (image && !recipe) {
    return (
      <div className="w-full animate__animated animate__fadeIn bg-white shadow-lg rounded-lg overflow-hidden border-4 relative">
        <div className="relative h-80 overflow-hidden bg-white">
          <div className="border-red-400 rounded-lg border-spacing-5">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: `url(${image})`,
              }}
            ></div>
          </div>
          <div className="absolute top-0 right-0 mt-2 z-10">
            <button
              onClick={onResetImageHandler}
              className=" m-2 px-4 py-2 bg-red-600 rounded font-bold text-white hover:opacity-80 transition"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  }

  const isOwner = () => recipe?.scannedById === user?.uid;
  const showSaveButton = () => !user || !isOwner;

  if (recipe) {
    return (
      <div className="w-full animate__animated animate__fadeIn bg-white shadow-lg rounded-lg overflow-hidden border-4 relative">
        <div className="relative h-80 overflow-hidden bg-white">
          <div className="border-red-400 rounded-lg border-spacing-5">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: `url(${recipe.image})`,
              }}
            ></div>
            {!permission.noActions && (
              <>
                {!permission.home && (
                  <div className="absolute top-0 right-0 p-4 z-10 flex w-full justify-end items-center sm:flex">
                    <div></div>
                    {showSaveButton && (
                      <div className="flex">
                        <SaveRecipeButton recipe={recipe} />
                      </div>
                    )}
                    <RecipeOptions recipe={recipe} shareOnly={true} />
                  </div>
                )}

                {permission.home && (
                  <div className="absolute top-0 right-0 p-4 z-10 flex w-full justify-between items-center sm:flex">
                    <button
                      onClick={processImageHandler}
                      className="flex items-center justify-center m-2 px-4 py-2 bg-[#fff4ed] rounded font-bold text-black hover:opacity-80 transition"
                    >
                      <ArrowPathIcon className="w-5 mr-0 sm:mr-2 bold" />
                      <span className="sm:block hidden">Regenerate</span>
                    </button>

                    <div className="flex">
                      <SaveRecipeButton recipe={recipe} />
                      <button
                        onClick={onResetImageHandler}
                        className=" bg-red-600  m-2 px-4 py-2 rounded font-bold text-white hover:opacity-80 transition"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="absolute inset-0 flex items-center justify-center flex-col bg-black/30">
            <h1 className="text-white pb-8 text-2xl font-bold text-center">
              {recipe.recipe_name} Recipe
            </h1>
            <div className="px-4 text-white text-sm mt-1">
              <p>
                <strong>Servings:</strong> {recipe.servings} |{" "}
                <strong>Total Time:</strong> {recipe.total_time}
              </p>
              {recipe?.calories && (
                <p>
                  <strong>Calories:</strong> {recipe?.calories}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* Ingredients Section */}
        {hasIngredients ? (
          <div className={`bg-[#fff4ed] curve-corner-down p-4 shadow-sm`}>
            <Disclosure defaultOpen={defaultExpand}>
              <DisclosureButton className="group w-full flex items-center gap-2 justify-between text-lg font-semibold text-gray-800">
                <h2 className="text-2xl font-emoji">Ingredients</h2>
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
              </DisclosureButton>

              <DisclosurePanel className="origin-top p-4 transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0">
                <ol className="list-inside list-disc">
                  {recipe.ingredients.map((ingredient, index) => (
                    <li key={index} className="mb-2">
                      {ingredient.quantity !== "N/A" && (
                        <span className="mr-2">{ingredient.quantity}</span>
                      )}
                      {ingredient.unit !== "N/A" && (
                        <span className="mr-2">{ingredient.unit}</span>
                      )}
                      <span>{ingredient.name}</span>
                    </li>
                  ))}
                </ol>
              </DisclosurePanel>
            </Disclosure>
          </div>
        ) : (
          <div className={`bg-[#fff4ed] curve-corner-down p-4 shadow-sm`}>
            <Disclosure defaultOpen={defaultExpand}>
              <DisclosureButton className="group w-full flex items-center gap-2 justify-between text-lg font-semibold text-gray-800">
                <h2 className="text-2xl font-emoji">Ingredients</h2>
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
              </DisclosureButton>

              <DisclosurePanel className="origin-top p-4 transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0">
                Not available
              </DisclosurePanel>
            </Disclosure>
          </div>
        )}

        {/* Instructions Section */}
        {hasInstructions ? (
          <div className="curve-corner p-4 shadow-sm">
            <Disclosure defaultOpen={defaultExpand}>
              <DisclosureButton className="group w-full flex items-center justify-between text-lg font-semibold text-gray-800">
                <h2 className="text-2xl font-emoji py-2">Instructions</h2>
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
              </DisclosureButton>
              <DisclosurePanel className="origin-top transition p-4 duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0">
                <ol className="list-inside list-decimal">
                  {recipe.instructions.map((instruction, index) => (
                    <li key={index} className="mb-2">
                      {instruction}
                    </li>
                  ))}
                </ol>
              </DisclosurePanel>
            </Disclosure>
          </div>
        ) : (
          <div className={`bg-[#fff4ed] curve-corner-down p-4 shadow-sm`}>
            <Disclosure defaultOpen={defaultExpand}>
              <DisclosureButton className="group w-full flex items-center gap-2 justify-between text-lg font-semibold text-gray-800">
                <h2 className="text-2xl font-emoji">Instructions</h2>
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
              </DisclosureButton>

              <DisclosurePanel className="origin-top p-4 transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0">
                Not available
              </DisclosurePanel>
            </Disclosure>
          </div>
        )}
      </div>
    );
  }

  return <></>;
};

export default RecipeFullDetails;
