function Footer() {
  return (
    <footer className="bg-gray-800 text-white w-full p-4  items-center justify-center text-center">
      <p>
        Click{' '}
        <a
          className="text-slate-300 underline"
          href="https://whatsapp.com/channel/0029VaiI0bVChq6SE7oIMs3o"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-whatsapp"></i>
          here
        </a>{' '}
        to join our channel for feedback and future updates.
      </p>
      <p>
        &copy; {new Date().getFullYear()} PulseSyncCooking by PulseSync Living
        (PTY) LTD. All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
