import { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../context/userContext';
import { LOGIN } from '../constant/routes';

type AuthGuardProps = {
  children: ReactNode;
};

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { user } = useUser();
  const isAuthenticated = !!user?.uid;
  const location = useLocation();

  //set timeout before run
  setTimeout(() => {
    if (!isAuthenticated) {
      return <Navigate to={LOGIN} replace state={{ from: location }} />;
    }
  }, 0);

  return <>{children}</>;
};

export default AuthGuard;
