import { Link } from 'react-router-dom';
import RecipeList from '../component/Recipe/recipeList';
import { MAX_SAVED_RECIPE } from '../constant';
import { useRecipeBook } from '../context/recipeBookContext';
import Head from '../component/Head';

const CookBook = () => {
  const { recipeBook } = useRecipeBook();
  const recipes = recipeBook.recipes;

  return (
    <>
      <Head
        title="My Cookbook | Pulsesync Cooking"
        description="Explore my collection of delicious recipes discovered with Pulsesync Cooking! From AI-generated meals to scanned favorites, find inspiration for your next culinary adventure."
        keywords="cookbook, personal recipes, saved recipes, AI recipes, scanned recipes, food inspiration, cooking ideas, recipe collection"
        image="%PUBLIC_URL%/logo/psl.png"
      />

      <div className="container mx-auto p-4 min-h-screen sm:p-8">
        <nav className="mb-4">
          <Link
            to={'/'}
            className="text-gray-400 font-bold hover:underline"
          >
            &larr; Back
          </Link>
        </nav>
        <h1 className="text-3xl font-bold mb-4">
          My Cookbook
          <span className="text-sm text-gray-400">
            [{recipes?.length}/{MAX_SAVED_RECIPE}]
          </span>
        </h1>
        {recipes && <RecipeList recipes={recipes} />}
      </div>
    </>
  );
};

export default CookBook;
