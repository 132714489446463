export const setStorageData = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

export const deleteStorageData = (key) => {
    localStorage.removeItem(key);
};

export const getStorageData = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
};