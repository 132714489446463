import {
  Dialog,
  Description,
  DialogTitle,
  Transition,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react';
import { Fragment, useRef } from 'react';

type ModalProps = {
  title?: string;
  description?: string;
  open: boolean;
  children: any;
  okButtonTitle?: string;
  cancelButtonTitle?: string;
  okAction?: () => void;
  cancelAction?: () => void;
  disableActions?: boolean;
};

export default function MyModal({
  title,
  description,
  open,
  children,
  okButtonTitle,
  cancelAction,
  okAction,
  cancelButtonTitle,
  disableActions,
}: ModalProps) {
  const dialogRef = useRef<HTMLDivElement>(null);
  const cancelActionHandler = () => {
    cancelAction();
  };

  const okActionHandler = () => {
    if (dialogRef?.current) {
      dialogRef?.current.scrollTo({ top: 0, behavior: 'smooth' });
    }

    okAction();
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="relative bg-white rounded-2xl p-6 text-left shadow-xl transform transition-all min-w-[50%] max-w-[80%]">
                  <DialogTitle
                    as="h1"
                    className="text-2xl font-bold leading-6 text-gray-900 text-center"
                  >
                    {title}
                  </DialogTitle>
                  <Description className="leading-6 text-sm text-gray-700 text-center">
                    {description}
                  </Description>

                  <div className="mt-2 w-full">{children}</div>
                  <div className="mt-4 flex ">
                    {okAction && (
                      <button
                        className="w-full mx-1 items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none hover:bg-gray-600 focus:outline-1 focus:outline-white"
                        disabled={disableActions}
                        onClick={okActionHandler}
                      >
                        {okButtonTitle || 'Ok'}
                      </button>
                    )}
                    {cancelAction && (
                      <button
                        className="w-full mx-1 items-center gap-2 rounded-md bg-white border-2 border-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-gray-700 shadow-inner shadow-white/10 focus:outline-none hover:bg-gray-200 focus:outline-1 focus:outline-white"
                        onClick={cancelActionHandler}
                      >
                        {cancelButtonTitle || 'Cancel'}
                      </button>
                    )}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
