import { createContext, useContext, useEffect, useState } from 'react';
import { setStorageData, getStorageData } from '../services/storage';
import { RecipeBook } from '../models/recipe.model';
import { useUser } from './userContext';
import { getRecipesByUserId } from '../services/firebase';

interface RecipeBookContextValue {
  recipeBook: RecipeBook;
  setRecipeBook: (type: RecipeActions, payload?: any) => void;
}

export enum RecipeActions {
  SAVE_RECIPE,
  SAVE_CURRENT_RECIPE,
  RESET_CURRENT,
  SAVE_CURRENT_SEARCH,
}

const RECIPE_BOOK = 'recipeBook';
const defaultRecipeBook: RecipeBook = {
  recipes: null,
  current: null,
  search: null
};

const RecipeBookContext = createContext<RecipeBookContextValue>(null);

export const RecipeBookProvider = ({ children }) => {
  const [recipeBook, setRecipeBook] = useState<RecipeBook>(defaultRecipeBook);
  const { user } = useUser();

  useEffect(() => {
    const data = getStorageData(RECIPE_BOOK);
    if (JSON.stringify(recipeBook) !== JSON.stringify(data)) {
      setRecipeBook(data);
    }
  }, [setRecipeBook, recipeBook]);

  useEffect(() => {
    if (!user) return;
    const unsubscribe = getRecipesByUserId(user.uid, (recipes) => {
      saveToRecipeBook(RecipeActions.SAVE_RECIPE, recipes);
    });

    return () => unsubscribe();
  }, [user]);

  const saveToRecipeBook = (type, payload = null) => {
    const data: RecipeBook = getStorageData(RECIPE_BOOK);

    switch (type) {
      case RecipeActions.SAVE_RECIPE: {
        const currentState = data ? data : defaultRecipeBook;
        currentState.recipes = payload;
        currentState.recipes = currentState.recipes.map((item) => ({
          ...item,
        }));
        setRecipeBook(currentState);
        setStorageData(RECIPE_BOOK, currentState);
        break;
      }
      case RecipeActions.SAVE_CURRENT_RECIPE: {
        const currentState = data ? data : defaultRecipeBook;
        currentState.current = payload;
        setRecipeBook(currentState);
        setStorageData(RECIPE_BOOK, currentState);
        break;
      }
      case RecipeActions.SAVE_CURRENT_SEARCH: {
        const currentState = data ? data : defaultRecipeBook;
        currentState.search = payload;
        setRecipeBook(currentState);
        setStorageData(RECIPE_BOOK, currentState);
        break;
      }
      case RecipeActions.RESET_CURRENT: {
        const currentState = data ? data : defaultRecipeBook;

        if (currentState?.current) {
          currentState.current = null;
          setRecipeBook(currentState);
          setStorageData(RECIPE_BOOK, currentState);
        }

        break;
      }
      default:
        break;
    }
  };

  return (
    <RecipeBookContext.Provider
      value={{ recipeBook, setRecipeBook: saveToRecipeBook }}
    >
      {children}
    </RecipeBookContext.Provider>
  );
};

export const useRecipeBook = () => useContext(RecipeBookContext);
