import { FC, useState } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { RecipeActions, useRecipeBook } from "../../context/recipeBookContext";
import { useUser } from "../../context/userContext";
import parseJsonString from "../../helpers/parseJson";
import { saveRecipeWithImage } from "../../services/firebase";
import {
  IMAGE_PROMPT,
  TEXT_PROMPT,
  GENERATION_CONFIG,
} from "../../configs/prompt";
import { useToaster } from "../../context/toasterContext";
import ToasterType from "../../models/toaster.enum";
import { Button } from "@headlessui/react";

type GenerateButtonProps = {
  isLoading: boolean;
  isDisabled?: boolean;
  handler?:() => void;
};

const GenerateButton: FC<GenerateButtonProps> = ({ isDisabled, isLoading, handler }) => {
  const [resIsLoading, setResIsLoading] = useState(false);
  const { setRecipeBook, recipeBook } = useRecipeBook();
  const user = useUser();
  const toast = useToaster();
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);
  const selectedIngredients = recipeBook?.search;

  const processIngredient = async () => {
    if (!selectedIngredients) return;

    let queryImage = null;
    let ingredientText = null;
    let prompt = null;

    const model = genAI.getGenerativeModel({
      model: "gemini-1.5-pro-001",
      generationConfig: GENERATION_CONFIG,
    });

    if (selectedIngredients?.byte) {
      queryImage = {
        inlineData: {
          data: selectedIngredients?.byte.split(",")[1],
          mimeType: "image/jpeg",
        },
      };

      prompt =  [IMAGE_PROMPT, queryImage];

    } else {
      /**
       * Convert selectedIngredients object to coma separated string
       */
      ingredientText = selectedIngredients
        .map((selectedIngredient) => {
          return selectedIngredient.value;
        })
        .join(", ");

      prompt = TEXT_PROMPT(ingredientText);

    }

    setResIsLoading(true);
    setRecipeBook(RecipeActions.SAVE_CURRENT_RECIPE, {
      loading: true,
    });


    try {
      let result = await model.generateContent(prompt);
      const resRecipes = parseJsonString(result.response.text());

      if (resRecipes) {
        
        const data = resRecipes.map((item) => ({
          ...item,
          scannedById: user?.user?.uid || null,
        }));

        const recipeData = await saveRecipeWithImage(data);

        setRecipeBook(RecipeActions.SAVE_CURRENT_RECIPE, {
          recipes: recipeData,
        });
      }
    } catch (error) {
      console.error("Error generating text:", error);
      toast(ToasterType.ERROR, "Failed to generate recipe, please try again.");
      setRecipeBook(RecipeActions.SAVE_CURRENT_RECIPE, {
        loading: false,
      });
    } finally {
      setResIsLoading(false);
    }
  };

  return (
    <Button
      disabled={(selectedIngredients?.length === 0) || isDisabled || isLoading || resIsLoading}
      onClick={() => {
        if(handler) {
          handler()
        }
        processIngredient(); 
      }}
      className={`w-full p-2 flex flex-col items-center justify-center text-gray-400 px-4 bg-gray-800 rounded-lg hover:bg-gray-900`}
    >
      {isLoading || resIsLoading ? <div className="loader p-2 "></div> : "Generate Recipe"}
    </Button>
  );
};
 
export default GenerateButton;
