import { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Switch } from '../icons';

function Capture({ setIsLoading, isLoading, setIngredientImage, setError }) {
  const webcamRef = useRef<Webcam | null>(null);
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [cameraMode, setCameraMode] = useState<'user' | 'environment'>(
    'environment'
  );
  const dimensions = !isLoading ? { height: 400 } : { width: 0, height: 0 };

  const handleUserMedia = () => {
    setIsLoading(false);
  };

  const onSetImageHandler = () => {
    const imageData = webcamRef.current?.getScreenshot();
    if (imageData) {
      setScreenshot(imageData);
      setIngredientImage(imageData);
    }
  };

  const toggleCameraMode = () => {
    setCameraMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  const videoConstraints = {
    facingMode: cameraMode,
  };

  return (
    <div className="my-4 text-center">
      {screenshot && !isLoading && (
        <div className="relative w-full">
          <img
            src={screenshot}
            className="w-full my-4 rounded-lg shadow-lg"
            alt="Captured"
          />
        </div>
      )}

      {!screenshot && (
        <div className="relative">
          <Webcam
            {...dimensions}
            ref={webcamRef}
            onUserMedia={handleUserMedia}
            className="rounded-lg shadow-lg w-full"
            videoConstraints={videoConstraints}
          />
          {!isLoading && (
            <>
              <button
                onClick={onSetImageHandler}
                className="w-full mt-4 px-4 py-2 bg-gray-800 rounded font-bold text-white hover:opacity-80 transition"
              >
                Capture
              </button>
              <button
                onClick={toggleCameraMode}
                className="absolute top-2 right-2 px-4 rounded font-bold text-white hover:opacity-80 transition"
              >
                <Switch />
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Capture;
