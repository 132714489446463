import { FC } from 'react';
import MyModal from '../modal';
import { Recipe } from '../../models/recipe.model';
import { Imessage, Twitter, Whatsapp } from '../icons';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

type ShareModalProps = {
  open: boolean;
  onClose: () => void;
  recipe: Recipe;
};

const ShareModal: FC<ShareModalProps> = ({ open, onClose, recipe }) => {
  const encodedUri = `${window.location.origin}/recipe/${recipe.id}`;
  const message = `Hey👋, I just created a recipe using the Pulsesync cooking app by scanning an image of the ingredients! Check out this awesome "${recipe.recipe_name}" recipe: . Try it here: ${encodedUri}`;

  const shareToTwitter = () => {
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const shareToWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const shareToEmail = () => {
    const url = `mailto:?subject=${encodeURIComponent(message)}&body=${encodedUri}`;
    window.open(url, '_blank');
  };

  const shareToiMessage = () => {
    const url = `sms:&body=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <MyModal
      open={open}
      cancelButtonTitle="Cancel"
      cancelAction={onClose}
      title="Share Recipe"
    >
      <div className="flex gap-4 space-y-4 justify-center">
        <button
          onClick={shareToTwitter}
          className=" w-16 pt-4 text-white rounded-md"
        >
          <Twitter />
        </button>
        <button
          onClick={shareToWhatsApp}
          className="w-14 text-white rounded-md"
        >
          <Whatsapp />
        </button>
        <button onClick={shareToEmail} className=" w-12 text-gray rounded-md">
          <EnvelopeIcon />
        </button>
        <button
          onClick={shareToiMessage}
          className="w-14 gray-white rounded-md"
        >
          <Imessage />
        </button>
      </div>
    </MyModal>
  );
};

export default ShareModal;
