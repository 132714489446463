import { useState } from 'react';

import { auth } from '../../configs/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useToaster } from '../../context/toasterContext';
import ToasterType from '../../models/toaster.enum';
import * as user from '../../transformer/user.transformer';
import { LOGIN } from '../../constant/routes';
import { saveData } from '../../services/firebase';
import { Field, Input, Label } from '@headlessui/react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

const Signup = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notice, setNotice] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToaster();
  const [loading, setLoading] = useState(false);

  const signupWithUsernameAndPassword = async (e) => {
    e.preventDefault();
    const id = toast(ToasterType.LOADING, 'Signing up...');
    setLoading(true);

    if (password === confirmPassword) {
      try {
        const res = await createUserWithEmailAndPassword(auth, email, password);

        if (res?.user) {
          await saveData(
            'users',
            res.user.uid,
            user.transform({ ...res.user, displayName: name })
          );

          navigate(LOGIN);
          setLoading(false);
          toast(ToasterType.UPDATE, 'Signup successful', {
            id,
            type: ToasterType.SUCCESS,
          });
        } else {
          throw new Error(
            'Something went wrong while creating the user account!'
          );
        }
      } catch (error) {
        setLoading(false);
        console.error('Error creating user document:', error);
        setNotice('Sorry, something went wrong. Please try again.');
        toast(ToasterType.UPDATE, 'Something went wrong', {
          id,
          type: ToasterType.ERROR,
        });
      }
    } else {
      setNotice("Passwords don't match. Please try again.");
      toast(ToasterType.UPDATE, "Passwords don't match. Please try again.", {
        id,
        type: ToasterType.ERROR,
      });
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center flex-col pt-4 m-4">
      <form
        className="w-full max-w-md bg-white rounded-lg shadow-lg p-6"
        onSubmit={(e) => signupWithUsernameAndPassword(e)}
      >
        {'' !== notice && (
          <div
            className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <span className="block sm:inline">{notice}</span>
          </div>
        )}
        <div>
          <h1 className="font-bold text-center text-2xl mb-4">Register</h1>
        </div>
        <div className="mb-4 flex gap-4">
          <Field>
            <Label
              htmlFor="lastName"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Name
            </Label>
            <Input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="Enter name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </Field>

          <Field>
            <Label
              htmlFor="lastName"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Last Name
            </Label>
            <Input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="lastName"
              type="text"
              placeholder="Enter last name"
              value={lastName}
              required
              onChange={(e) => setLastName(e.target.value)}
            />
          </Field>
        </div>

        <Field className="mb-4">
          <Label
            htmlFor="email"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Email address
          </Label>
          <Input
            type="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            placeholder="Enter email"
            value={email}
            autoComplete="password"
            required // Add required attribute
            onChange={(e) => setEmail(e.target.value)}
          />
        </Field>

        <Field className="mb-4 relative">
          <Label
            htmlFor="password"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Password
          </Label>
          <Input
            type={showPassword ? 'text' : 'password'}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            placeholder="Password"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute top-9 right-0 flex items-center px-3 text-gray-700 hover:text-gray-900 focus:outline-none"
          >
            {!showPassword ? (
              <EyeIcon className="h-5 w-5" />
            ) : (
              <EyeSlashIcon className="h-5 w-5" />
            )}
          </button>
        </Field>
        <Field className="mb-6 relative">
          <Label
            htmlFor="confirmPassword"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Confirm Password
          </Label>
          <Input
            type={showPassword ? 'text' : 'password'}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="confirmPassword"
            placeholder="Confirm Password"
            value={confirmPassword}
            required
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Field>
        <button
          type="submit"
          disabled={loading}
          className="w-full bg-gray-800 hover:bg-black-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Signup
        </button>

        <div className="text-center mt-4">
          <span>
            Already have an account?{' '}
            <p
              onClick={() => {
                navigate(LOGIN);
              }}
              className="text-blue-500 hover:underline"
            >
              Login here.
            </p>
          </span>
        </div>
      </form>
    </div>
  );
};

export default Signup;
