export const AUTHENTICATE = '/authenticate';
export const LOGIN = '/authenticate/login';
export const SIGN_UP = '/authenticate/signup';
export const FORGOT_PASSWORD = '/authenticate/forgot-password';
export const HOME = '/';
export const RECIPES = '/recipe';
export const VERIFY_EMAIL = '/authenticate/verify-email';
export const RESET_PASSWORD = '/authenticate/reset-password'
export const UPLOAD = '/generator?tab=0'
export const CAPTURE = '/generator?tab=1'
export const GENERATOR = '/generator'
export const ADMIN = '/admmn'

