import { useState } from 'react';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import {
  EllipsisVerticalIcon,
  TrashIcon,
  ShareIcon,
} from '@heroicons/react/20/solid';
import MyModal from '../../modal';
import { deleteRecipe } from '../../../services/firebase';
import { useUser } from '../../../context/userContext';
import { useToaster } from '../../../context/toasterContext';
import ToasterType from '../../../models/toaster.enum';
import ShareModal from '../../shareModal';

const RecipeOptions = ({ recipe, shareOnly = false }) => {
  const [confirm, setConfirm] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const { user } = useUser();
  const toast = useToaster();

  const handleDelete = () => {
    const id = toast(ToasterType.LOADING, 'Deleting recipe...');

    deleteRecipe(user.uid, recipe.id).then(
      () => {
        toast(ToasterType.UPDATE, 'Recipe deleted', {
          id,
          type: ToasterType.SUCCESS,
          actionHandler: () => {
            console.log('view list');
          },
        });

        setConfirm(false);
      },
      (err) => {
        setConfirm(false);

        toast(ToasterType.UPDATE, err.message, {
          id,
          type: ToasterType.ERROR,
        });
      }
    );
  };

  return (
    <>
      {!shareOnly ? (
        <Menu as="div" className="relative  text-left">
          <MenuButton className="inline-flex items-center p-2 bg-slate-800/10 rounded-xl  text-white hover:bg-gray-700/10 focus:outline-none">
            <EllipsisVerticalIcon className="h-5 w-5 text-white" />
          </MenuButton>
          <MenuItems className="absolute right-0 mt-2 origin-top-right bg-white divide-y rounded-md">
            <MenuItem>
              <button
                className="group flex items-center w-full px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100"
                onClick={() => setShareOpen(true)} // Open the ShareModal
              >
                <ShareIcon className="h-4 w-4 mr-2" />
                Share
              </button>
            </MenuItem>
            <MenuItem>
              <button
                className="group flex items-center w-full px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-100"
                onClick={() => setConfirm(true)}
              >
                <TrashIcon className="h-4 w-4 mr-2" />
                Delete
              </button>
            </MenuItem>
          </MenuItems>
        </Menu>
      ) : (
        <button
          className="flex items-center justify-center m-2 px-4 py-2 bg-[#fff4ed] rounded font-bold text-black hover:opacity-80 transition"
          onClick={() => setShareOpen(true)} // Open the ShareModal
        >
          <ShareIcon className="w-5 mr-0 sm:mr-2 bold" />
        </button>
      )}
      <MyModal
        open={confirm}
        title="Confirm delete"
        description={`Are you sure you want to delete ${recipe.recipe_name} recipe?`}
        okAction={handleDelete}
        cancelAction={() => setConfirm(false)}
      >
        <></>
      </MyModal>
      <ShareModal
        open={shareOpen}
        onClose={() => setShareOpen(false)}
        recipe={recipe}
      />
    </>
  );
};

export default RecipeOptions;
