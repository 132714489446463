export const Switch = () => (
  <img src="/switch.png" className="w-8 h-8" alt="loading" />
);

export function Facebook(props: any) {
  return (
    <img {...props} src="/icons/facebook.gif" alt="this slowpoke moves"></img>
  );
}

export function Twitter(props: any) {
  return (
    <img {...props} src="/icons/twitter.gif" alt="this slowpoke moves"></img>
  );
}

export function Whatsapp(props: any) {
  return (
    <img {...props} src="/icons/whatsapp.gif" alt="this slowpoke moves"></img>
  );
}

export function Imessage(props: any) {
  return (
    <img {...props} src="/icons/imessage.png" alt="this slowpoke moves"></img>
  );
}
