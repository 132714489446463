import { useLocation } from 'react-router-dom';
import ResetPassword from '../component/resetPassword';
import { useEffect, useState } from 'react';
import EmailVerified from '../component/verifyEmail/emailVerified';

export default function Auth() {
  const [mode, setMode] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get('mode');
    setMode(mode);
  }, [location]);

  if (mode === 'verifyEmail') {
    return <EmailVerified />;
  }

  return <ResetPassword />;
}
